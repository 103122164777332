import React from 'react';

import Redirect from '@/components/Redirect';

import Toast from '@/utils/toast';

import { StyledContainer } from './styles';

function RedirectPage() {


  return (

      <Redirect

      />

   
  );
}

export default Redirect;

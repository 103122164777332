import React,{useEffect} from 'react';
import axios from 'axios';
import { StyledContainer } from './styles';
import { relative } from './styles';
import {StyledNeutronLogo} from "@/components/Redirect/styles";
import { BoxLoading } from 'react-loadingg';
import Box from '@material-ui/core/Box';


export default function Redirect(props) {

  useEffect(() => {
    let basicAuth = 'Basic ' + btoa(process.env.REACT_APP_PUBLIC_KEY + ':' + process.env.REACT_APP_PRIVATE_KEY);

    axios.post(process.env.REACT_APP_URL_APIFY+'/login',{},{
      headers: { 'Authorization': basicAuth }
    })
    .then(res => {

      let token = 'Bearer '+  res.data.token;
      axios.get(process.env.REACT_APP_URL_APIFY+'/links/'+props.match.params.id,{
        headers: { 'Authorization': token,'Content-Type':'application/json' }
      }).then(res2 => {
         // props.history.push(res2.data.original_url)
        if(typeof res2.data.data.original_url  !== 'undefined'){
          window.location.href = res2.data.data.original_url;
        }


      })

    })
  });
  // console.log(props.match.params.id)

  return (

    <div style={{ width: '100%' }}>
      {/*<Box display="flex" p={1} bgcolor="background.paper">*/}
      {/*  <StyledContainer>*/}

      {/*    /!*<img src={'https://369969691f476073508a-60bf0867add971908d4f26a64519c2aa.ssl.cf5.rackcdn.com/logos/logo_epayco_200px.png'}/>*!/*/}

      {/*    /!*<small>*!/*/}
      {/*    /!*  <h1>Redireccionando... </h1>*!/*/}

      {/*    /!*</small>*!/*/}

      {/*  </StyledContainer>*/}
      {/*</Box>*/}
    </div>
  );
}

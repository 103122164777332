import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { ConnectedRouter } from 'connected-react-router';


import Home from '@/pages/Home';
import Redirect from '@/pages/Redirect';

import history from './history';

const Routes = () => (
  <ConnectedRouter history={history}>
    <Switch>
      <Route path="/:id" render={(props) => <Redirect {...props}/> } />
    </Switch>
  </ConnectedRouter>
);

export default Routes;

import {
  all,
  // takeLatest,
} from 'redux-saga/effects';

// import { SessionTypes } from '../ducks/session';
// import { getSessionRequest } from './session';

export default function* rootSaga() {
  yield all([
    // takeLatest(SessionTypes.GET_SESSION_REQUEST, getSessionRequest),
  ]);
}
